<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder">
                    <label>Employee Phone Number </label>
                    <the-mask v-if="this.userPhone" type="text" @focus="ResetDirty('EmployeePhoneNumber')"
                        @blur="SetDirty('EmployeePhoneNumber')" :tokens="{ '#': { pattern: /\d/ } }"
                        :mask="['##', '1-###-###-####']" style="margin-top:1px;" class="form-control" autocomplete="off"
                        :maxlength="14" v-model="ProdEXPAmend.EmployeePhoneNumber" placeholder="Employee Phone Number"
                        disabled></the-mask>

                    <b-form-input v-else placeholder="N/A" disabled></b-form-input>

                    <div class="error-message-format"
                        v-if="$v.ProdEXPAmend.EmployeePhoneNumber.$dirty && !$v.ProdEXPAmend.EmployeePhoneNumber.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder">
                    <label>AGENCY </label>
                    <OmniSelect placeholder="Select Agency" name="Media" :showLabel="false" label="Select Media"
                        :options="AgencyList" v-model="ProdEXPAmend.EmployeeAgency" />
                </div>
                <div class="fieldborder" style="margin-top: 0px;">
                    <b-form-group label="" class="timeonly-text">
                        <label>REQUEST TYPE </label>
                        <b-form-radio-group style="margin-left:10px;" class="omniipclass" id="time-only"
                            v-model="ProdEXPAmend.RequestType" name="time-only">
                            <b-form-radio value="production">PRODUCTION</b-form-radio>
                            <b-form-radio value="expense">EXPENSE</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <div class="fieldborder mt-n2">
                    <label>Select type of change requested </label>
                    <OmniSelect placeholder="Select type of change requested" name="Media" :showLabel="false" label="Select Media"
                        :options="ChangeTypeOptions" v-model="ProdEXPAmend.ChangeType" />
                </div>
            </div>
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
                NEXT
            </button>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import service from "../../../services/Dropdowns";
import { required, minLength, requiredIf, maxLength, numeric } from "vuelidate/lib/validators";
import { TheMask } from 'vue-the-mask'
export default {
    data() {
        return {
            reachUserData:null,
            userPhone: null,
            AgencyList: [],
            ChangeTypeOptions: [{
                    label: "Address",
                    value: "Address"
                },{
                    label: "Banking",
                    value: "Banking"
                },{
                    label: "Both",
                    value: "Both"
             },],
        }
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        },
    },
    components: {
        TheMask
    },
    validations: {
        ProdEXPAmend: {
            EmployeePhoneNumber: {
                required: requiredIf(function () {
                    return this.userPhone;
                }),
                minLength: minLength(10),
                maxLength: maxLength(10),
                numeric
            },
            EmployeeAgency: {
                required: required,
            },
            RequestType: {
                required: required,
            },
            ChangeType: {
                required: required,
            },
        }
    },
    computed: {
        CanCreatProductionExpenseNewMediaRequest() {
            return this.reachUserData && this.reachUserData.MatrixEmailID && this.reachUserData.MatrixEmailID.trim() != "";
        },
        hasMinimumOne() {
            return this.ProdEXPAmend.EmployeePhoneNumber.includes('1');
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('prodexpamend', {
            ProdEXPAmend: 'ProdEXPAmend',
        }),
    },
    async mounted() {
     await this.getPhoneNumber();
         this.LoadAgency();
        if (!this.CanCreatProductionExpenseNewMediaRequest) {
            this.$router.push({
                name: "error",
                params: { message: "UnAuthorized" },
            });
        }
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    created() {
    },
    watch: {
        "ProdEXPAmend.EmployeeAgency": function (val) {
            this.UpdateAgencyName(val);
        },
        "ProdEXPAmend.ChangeType": function (val) {
            if (val == "Address") {
                this.ProdEXPAmend.BankName = '';
                this.ProdEXPAmend.BankCountry = '';
                this.ProdEXPAmend.BankState = '';
                this.ProdEXPAmend.BankAddressLine1 = '';
                this.ProdEXPAmend.BankAddressLine2 = '';
                this.ProdEXPAmend.BankCity = '';
                this.ProdEXPAmend.BankZipCode = '';
                this.ProdEXPAmend.AccountName = '';
                this.ProdEXPAmend.AccountNumber = '';
                this.ProdEXPAmend.RoutingNumber = '';
                this.ProdEXPAmend.BankBIC = '';
                this.ProdEXPAmend.BankIBAN = '';
                this.ProdEXPAmend.RemittanceAdviceEmail = '';
                this.ProdEXPAmend.BankLetter = [];
            }
            else if (val == "Banking") {
                this.ProdEXPAmend.RemittanceCountry = '';
                this.ProdEXPAmend.StreetAddressLine1 = '';
                this.ProdEXPAmend.StreetAddressLine2 = '';
                this.ProdEXPAmend.RemittanceCity = '';
                this.ProdEXPAmend.RemittanceState = '';
                this.ProdEXPAmend.RemittanceZipCode = '';
            }
        },
    },
    methods: {
        async getPhoneNumber() {
            try {
                const emailAddress = await this.$store.dispatch("getEmail");
                const res = await this.$store.dispatch("product/GetReachUser", emailAddress);
                if (res.data) {
                    this.ProdEXPAmend.EmployeePhoneNumber = res.data.Phone;
                    this.userPhone = this.ProdEXPAmend.EmployeePhoneNumber ? this.ProdEXPAmend.EmployeePhoneNumber.length : null;
                    this.reachUserData = res.data;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        SetDirty(propertyName) {
            this.$v.ProdEXPAmend[propertyName].$touch();
        },
        ResetDirty(propertyName) {
            this.$v.ProdEXPAmend[propertyName].$reset();
        },
        async LoadAgency() {
            service
                .getAgencyDropdownValues("")
                .then((res) => {
                    this.AgencyList = res.data;
                    this.UpdateAgencyName(this.ProdEXPAmend.EmployeeAgency)
                })
                .catch((err) => {
                    alert(err);
                });
        },
        UpdateAgencyName(newVal) {
            const agencyValue = this.AgencyList.findIndex((item) => {
                return item.value == newVal;
            });
            if (agencyValue > -1) {
                this.ProdEXPAmend.AgencyName = this.AgencyList[agencyValue].label;
            } else {
                this.ProdEXPAmend.AgencyName = "";
            }
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Amend Setup Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            }
            else {
                this.$router.push({
                    name: "Production Expense Amend Setup Info"
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        }

    }
}
</script>

<style scoped>
.footer-buttons {
    z-index: 9;
}

.agree-checkbox .custom-control-label {
    font-weight: 400;
    font-size: 12px;
}
</style>
